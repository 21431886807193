import React from 'react'
import { Box, Heading, Link } from '@chakra-ui/react'
import Layout from '../components/Layout'
import ScholarshipApplicationEmbed from '../components/ScholarshipApplicationEmbed'
import { ExternalLinkIcon } from '@chakra-ui/icons'

const ScholarshipApplication: React.FC = () => {
  return (
    <Layout page_title=''>
      <Box mt={8} mb={8}>
        {/* <Heading
          as='h2'
          marginLeft='-15px'
          size='lg'
          mb={4}
          fontFamily='Cherry Swash'
          color='blue.500'>
          Our Story
        </Heading> */}
        {/* <ScholarshipApplicationEmbed />{' '}https://docs.google.com/forms/d/1EccAxbHitrVmUf6jbPn79Mn_8M2h-nRJ_zQEh9D2r1M/edit */}
        <Link
          isExternal
          color='blue.500'
          href='https://docs.google.com/forms/d/1EccAxbHitrVmUf6jbPn79Mn_8M2h-nRJ_zQEh9D2r1M/edit'>
          Shine Like Lauren Scholarship Application 2025
          <ExternalLinkIcon mx='1px' />
        </Link>
      </Box>
    </Layout>
  )
}

export default ScholarshipApplication
