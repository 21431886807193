import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Nav from './components/Nav'
import EventDetail from './pages/EventDetail'
import { ChakraProvider, Spinner } from '@chakra-ui/react'
import Theme from './theme/Theme'
import React, { Suspense, lazy } from 'react'
import Recipients from './pages/Recipients'
import DonationButton from './components/DonationButton'
import ScholarshipApplication from './pages/ScholarshipApplication'

const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const Donate = lazy(() => import('./pages/Donate'))
const Events = lazy(() => import('./pages/Events'))
const Sponsors = lazy(() => import('./pages/Sponsors'))
const ImageGallery = lazy(() => import('./pages/ImageGallery'))
const ImageGalleryDetail = lazy(() => import('./pages/ImageGalleryDetail'))

const App = () => {
  return (
    <Router>
      <ChakraProvider theme={Theme}>
        <Nav aria-label='Main Navigation' />
        <Suspense
          fallback={
            <Spinner
              thickness='4px'
              speed='0.65s'
              emptyColor='gray.200'
              color='blue.500'
              size='xl'
            />
          }>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/about' element={<About />} />
            <Route path='/apply' element={<ScholarshipApplication />} />
            <Route path='/donate' element={<Donate />} />
            <Route path='/events' element={<Events />} />
            <Route path='/events/:id' element={<EventDetail />} />
            <Route path='/sponsors' element={<Sponsors />} />
            <Route
              path='/gallery'
              element={
                <ImageGallery
                  galleryTitle='Lauren Gallery'
                  fileName='laurenImageData.json'
                  detailPath='/image-detail'
                />
              }
            />
            <Route
              path='/image-detail'
              element={
                <ImageGalleryDetail
                  parentPath='/gallery'
                  fileName='laurenImageData.json'
                />
              }
            />
            <Route
              path='/event-gallery'
              element={
                <ImageGallery
                  galleryTitle='Event Gallery'
                  fileName='eventImageData.json'
                  detailPath='/event-image-detail'
                />
              }
            />
            <Route
              path='/event-image-detail'
              element={
                <ImageGalleryDetail
                  parentPath='/event-gallery'
                  fileName='eventImageData.json'
                />
              }
            />
            <Route path='/recipients' element={<Recipients />} />
          </Routes>
        </Suspense>
        <DonationButton />
      </ChakraProvider>
    </Router>
  )
}

export default App
