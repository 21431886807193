import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Heading,
  Text,
  Image,
  HStack,
  VStack,
  Button,
  Flex,
  IconButton,
  Skeleton,
} from '@chakra-ui/react'
import { useClipboard } from '@chakra-ui/react'
import Layout from '../components/Layout'
import eventsData from '../data/events.json'

import EventBdubs from '../components/EventBdubs'
import EventBlueAndWhite from '../components/EventBlueAndWhite'
import EventSkyline from '../components/EventSkyline'
import { handleFacebookShare } from '../utils/shareUtils' // Import the utility function
import EventBlueAndWhite2025 from '../components/EventBlueAndWhite2025'
import EventCityBbq from '../components/EventCityBbq'

const componentMap: { [key: string]: React.FC } = {
  EventBdubs,
  EventBlueAndWhite,
  EventBlueAndWhite2025,
  EventSkyline,
  EventCityBbq,
}

interface Event {
  id: number
  title: string
  date: string
  location: string
  image: string
  detailImage: string
  descriptionComponent: string
}

const EventDetail: React.FC = () => {
  const { id } = useParams<{ id?: string }>()
  const { hasCopied, onCopy } = useClipboard(window.location.href)
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState<Event | null>(null)

  const formatDate = (dateString: string) => {
    const isoDateString = new Date(dateString).toISOString()
    const date = new Date(isoDateString)
    return date.toLocaleDateString('en-US', {
      timeZone: 'UTC',
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 500)

    if (id !== undefined) {
      const eventId = parseInt(id)
      const eventData = eventsData.find(event => event.id === eventId)

      if (eventData) {
        setEvent(eventData)
      } else {
        console.error(`Event with id ${id} not found.`)
      }
    }

    return () => clearTimeout(timer)
  }, [id])

  if (!event) {
    return (
      <Layout>
        <Skeleton height='400px' />
      </Layout>
    )
  }

  const DescriptionComponent = componentMap[event.descriptionComponent]
  const handleFacebookClick = () => {
    handleFacebookShare(window.location.href)
  }
  return (
    <Layout>
      <Box mb='8'>
        {event.detailImage && (
          <Image
            src={event.detailImage}
            alt={event.title}
            borderRadius='md'
            boxShadow='md'
            maxH='200px'
          />
        )}
      </Box>
      <VStack
        spacing={4}
        align='stretch'
        border='1px solid'
        borderColor='gray.200'
        borderRadius='md'
        p={4}
        boxShadow='md'>
        <Heading
          as='h1'
          size='xl'
          mb='4'
          textAlign='center'
          color='blue.500'
          fontFamily='Cherry Swash'>
          {event.title}
        </Heading>
        <HStack justifyContent='space-between'>
          <Flex alignItems='center'>
            <Text fontWeight='bold'>Date:</Text>
            <Text ml={2}>{formatDate(event.date)}</Text>
          </Flex>
          <Flex alignItems='center'>
            <Text fontWeight='bold'>Location:</Text>
            <Text ml={2}>{event.location}</Text>
          </Flex>
        </HStack>
        <HStack spacing={4} justifyContent='flex-end'>
          <Button onClick={onCopy} colorScheme='blue' variant='outline'>
            {hasCopied ? 'Copied' : 'Copy Event URL'}
          </Button>
          <IconButton
            aria-label='Share on Facebook'
            icon={
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                fill='currentColor'
                width='24px'
                height='24px'>
                <path d='M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.406.593 24 1.325 24H12.82v-9.294H9.691v-3.622h3.13V8.413c0-3.1 1.893-4.788 4.656-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.31h3.587l-.467 3.622h-3.12V24h6.116C23.406 24 24 23.406 24 22.676V1.325C24 .593 23.406 0 22.675 0z' />
              </svg>
            }
            colorScheme='facebook'
            onClick={handleFacebookClick}
          />
        </HStack>
        <Box
          border='1px solid'
          borderColor='gray.200'
          borderRadius='md'
          p={4}
          boxShadow='md'>
          <DescriptionComponent />
        </Box>
      </VStack>
    </Layout>
  )
}

export default EventDetail
