import { Box } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const DonationButton: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://widgets.givebutter.com/latest.umd.cjs?acct=wDkwBPCqVCJYeJWU&p=other'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  const location = useLocation()

  return (
    <>
      {location.pathname !== '/donate' && (
        <Box position='fixed' bottom='20px' right='20px'>
          <div
            dangerouslySetInnerHTML={{
              __html: '<givebutter-widget id="pnzAzp"></givebutter-widget>',
            }}
          />
        </Box>
      )}
    </>
  )
}

export default DonationButton
